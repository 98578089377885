import React, { useCallback } from 'react';

import {
  ButtonNew,
  Modal,
  Text,
  ModalContext,
  ProgressiveImage,
} from '../../components';
import modalTypes from '../../constants/modalTypes';
import appUrls from '../../constants/appUrls';

const koko = require('../../images/papiga_koko_1.svg');
const kokoPlaceholder = require('../../images/papiga_koko_1-min.webp');

const ModalPromo = () => {
  const { modalData, setModalData } = React.useContext(ModalContext);

  const close = useCallback(() => {
    setModalData({ type: modalTypes.NONE, props: {} });
  }, [setModalData]);

  return (
    <Modal
      show={modalData.type === modalTypes.PROMO}
      onClose={close}
      className="promo-modal"
    >
      <ProgressiveImage
        src={koko}
        placeholderSrc={kokoPlaceholder}
        alt="koko"
      />
      <Modal.Title>BACK TO SCHOOL AKCIJA</Modal.Title>
      <Modal.Description>
        <Text>
          30% popusta na Kokolingo softverske pakete uz promo kod:
          <br />
          <b>SKOLA30</b>
          <br />
          Požurite! Popust vrijedi do 13. rujna.
        </Text>
        <ButtonNew
          className="my-3"
          size="small"
          onClick={() => {
            window.location.href = `${appUrls.APP_LOGIN}?redirect=${appUrls.APP_SUBSCRIPTION_STATUS}`;
          }}
        >
          PRIJAVITE SE OVDJE
        </ButtonNew>
      </Modal.Description>
    </Modal>
  );
};

export default ModalPromo;
